.contact-button {
    display: inline-flex;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    justify-content: center;
    box-shadow: 12px 12px 24px rgba(31, 91, 72,.64);
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  
  .contact-button::before {
    content: '';
    box-shadow: 0 0 60px rgba(31, 91, 72,.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-button:hover,
  .contact-button:focus {
    color: #ffffff;
    transform: translateY(-6px);
  }
  
  .contact-button:hover::before,
  .contact-button:focus::before {
    opacity: 1;
  }
  
  .contact-button::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid #1f5b48;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 30s infinite;
}
  
  .contact-button:hover::after,
  .contact-button:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1;
    }
    10% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}