@tailwind base;
@tailwind components;
@tailwind utilities;

  
  .custom-frame::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    @apply md:w-2 md:h-2 lg:w-[10px] lg:h-[10px] xl:w-[12px] xl:h-[12px];
  }

  .custom-frame2::before,
  .custom-frame2::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    @apply md:w-2 md:h-2 lg:w-[10px] lg:h-[10px] xl:w-[12px] xl:h-[12px];

  }
  
  .custom-frame::before {
    top: -5px;
    left: -5px;
    @apply md:top-[-6px] md:left-[-6px] lg:top-[-7px] lg:left-[-7px] xl:top-[-8px] xl:left-[-8px];
  }
  
  .custom-frame2::before {
    bottom: -5px;
    left: -5px;
    @apply md:bottom-[-6px] md:left-[-6px] lg:bottom-[-7px] lg:left-[-7px] xl:bottom-[-8px] xl:left-[-8px];
  }

  .custom-frame2::after {
    top: -5px;
    right: -5px;
    @apply md:top-[-6px] md:right-[-6px] lg:top-[-7px] lg:right-[-7px] xl:top-[-8px] xl:right-[-8px];
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  .rotate-icon {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }
  
  .rotate-icon.rotate {
    animation: rotate 0.3s forwards;
  }
